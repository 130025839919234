<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="70%"
    class="adaptationModal"
    @close="onDialogClose">
    <div v-loading="contentLoading">
      <el-form
        ref="formValidate"
        :model="maintType"
        :label-width="$l('maintWorkOrder.labelWidth','100px')"
        :rules="ruleValidate">
        <el-form-item prop="maintTypeName" :label="$l('maintWorkOrder.maintTypeName','保养类型名称')">
          <el-input
            v-model.trim="maintType.maintTypeName"
            :placeholder="$t('common.pleaseEnter')"
            maxlength="40">
          </el-input>
        </el-form-item>
        <el-form-item prop="elevatorProductType" :label="$l('maintWorkOrder.productTypeName','梯种')">
          <el-select
            v-model="maintType.elevatorProductType"
            multiple
            :placeholder="$t('common.pleaseSelect')"
            clearable>
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="maintTypeClassify" :label="$l('maintWorkOrder.maintTypeClassify','保养类型分类')">
          <el-select
            v-model="maintType.maintTypeClassify"
            :placeholder="$t('common.pleaseSelect')"
            clearable>
            <el-option
              v-for="item in maintTypeClassify"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="remark" :label="$l('maintWorkOrder.remark','备注')">
          <el-input
            v-model.trim="maintType.remark"
            type="textarea"
            :rows="1"
            :placeholder="$t('common.pleaseEnter')"
            maxlength="255">
          </el-input>
        </el-form-item>
        <el-form-item v-if="currentUserType === 0" prop="isDefault" :label="$l('maintWorkOrder.isDefault','是否默认')">
          <el-switch v-model="maintType.isDefault" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          @click="$refs.selectMaintItem.open(maintItemList)">
          {{$l("maintWorkOrder.selectMaintItem","选择保养类型项目")}}
        </el-button>
      </div>
      <el-table
        :data="maintItemList"
        border
        stripe
        style="width: 100%; margin-top: 10px"
        max-height="400">
        <el-table-column type="index" :label="$l('maintWorkOrder.no','序号')" width="60px" align="center"></el-table-column>
        <el-table-column prop="itemName" :label="$l('maintWorkOrder.itemName','保养项名称')"></el-table-column>
        <el-table-column prop="maintContent" :label="$l('maintWorkOrder.maintContent','保养内容')"></el-table-column>
        <el-table-column prop="maintRequire" :label="$l('maintWorkOrder.maintRequire','保养要求')"></el-table-column>
        <el-table-column prop="maintArea" :label="$l('maintWorkOrder.maintArea','保养区域')" width="100px"></el-table-column>
        <el-table-column prop="remark" :label="$l('maintWorkOrder.remark','备注')"></el-table-column>
        <el-table-column :label="$t('common.operation')" width="85px" align="center">
          <template v-slot="scope">
            <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
              {{$t("common.remove")}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
    <maint-item-select ref="selectMaintItem" @multi-select="handleMultiItemSelect"></maint-item-select>
  </el-dialog>
</template>

<script>
  import MaintItemSelect from "@/views/newMaintWorkOrder/maintSetting/maintItemSelect";
  import auth from "@/util/auth";

  const moduleName = "maintType";
  export default {
    components: {MaintItemSelect},
    data() {
      return {
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        currentUserType: auth.getUserType(),
        productTypeList: [],
        maintItemList: [],
        maintTypeClassify: [
          {value: "半月保", label: this.$l("maintWorkOrder.type.halfMonth","半月保")},
          {value: "季保", label: this.$l("maintWorkOrder.type.month","季保")},
          {value: "半年保", label: this.$l("maintWorkOrder.type.halfYear","半年保")},
          {value: "年保", label: this.$l("maintWorkOrder.type.year","年保")},
        ],
        maintType: {
          id: 0,
          maintTypeName: "",
          elevatorProductType: "",
          maintTypeClassify: "",
          remark: "",
          maintItemIdList: [],
          isDefault: 0,
        },
        ruleValidate: {
          maintTypeName: [
            {required: true, message: this.$l("maintWorkOrder.tip.maintTypeName","保养类型名称不能为空"), trigger: "blur"},
          ],
          elevatorProductType: [
            {required: true, message: this.$l("maintWorkOrder.tip.elevatorProductType","梯种不能为空"), trigger: "blur"},
          ],
          maintTypeClassify: [
            {required: true, message: this.$l("maintWorkOrder.tip.maintTypeClassify","保养类型分类不能为空"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.maintType.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " +
          this.$l("maintWorkOrder.maintType","保养类型");
      },
    },
    methods: {
      open(id) {
        this.maintType.id = id;
        this.dialogVisible = true;
        this.getElevatorProductTypeList();
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.maintType.id).then(res => {
          this.maintType = res.data;
          this.maintType.elevatorProductType = this.maintType.elevatorProductType.split(",");
          this.getMaintItemList();
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getMaintItemList() {
        let param = {
          idList: this.maintType.maintItemIdList.toString(),
        };
        this.$api.getData("maintItem/type", param).then(res => {
          this.maintItemList = res.data;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$l("maintWorkOrder.tip.getMaintTypeItemError", "获取保养类型项目失败") + "," + error.response.data.message);
          }
        });
      },
      getElevatorProductTypeList() {
        this.$api.getList("elevatorProductTypes").then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = {value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeName};
            } else {
              item = {value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeNameEn};
            }
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$l("maintWorkOrder.tip.getElevatorProductTypeError","获取电梯梯种失败") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.$refs.formValidate.resetFields();
        if (this.$refs.selectMaintItem.$refs.selectTable) {
          this.$refs.selectMaintItem.$refs.selectTable.$refs.elTable.clearSelection();
          this.$refs.selectMaintItem.$refs.selectTable.handleClearClick();
          this.$refs.selectMaintItem.flag = 0;
        }
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            if (this.maintType.id === 0) {
              this.maintType.id = null;
            }
            this.maintType.elevatorProductType = this.maintType.elevatorProductType.toString();
            this.maintType.maintItemIdList = this.maintItemList.map(item => item.id);
            this.submitLoading = true;
            this.$api.save(moduleName, this.maintType).then(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$refs.selectMaintItem.$refs.selectTable.$refs.elTable.clearSelection();
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      handleMultiItemSelect(rows) {
        this.maintItemList = rows;
      },
      deleteRow(index) {
        this.maintItemList.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
