<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="branchAgency">
      <el-button v-if="editAuth" slot="toolbar" class="addBtn" @click="$refs.branchAgencyEdit.open(0,3)">
        {{ $l("elevatorNetApply.add", "+ 新增") }}
      </el-button>
      <el-table-column prop="branchName" :label="$l('contract.branchName', '服务站名称')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="creator" :label="$l('gatewayVersion.creator', '创建人')" align="center" width="100"></el-table-column>
      <el-table-column prop="createTime" :label="$l('msgPushRecord.createTime', '创建时间')" align="center" width="140"></el-table-column>
      <el-table-column v-if="editAuth" :label="$l('elevatorNetApply.operate', '操作')" align="center" width="100" fixed="right">
        <template v-slot="scope">
          <el-button type="text" @click="$refs.branchAgencyEdit.open(scope.row.id)">
            {{ $l("elevatorNetApply.edit", "编辑") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{ $l("elevatorNetApply.delete",
                  "删除") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <branch-agency-edit ref="branchAgencyEdit" @save-success="getList(-1)"></branch-agency-edit>
  </div>
</template>

<script>
  import BranchAgencyEdit from "@/views/newMaintWorkOrder/maintSetting/branchAgencyEdit";
  import auth from "@/util/auth";

  export default {
    components: { BranchAgencyEdit },
    data() {
      return {
        loading: true,
        editAuth:auth.getUserType() === 1 || auth.getUserType() === 0,
        search: {
          type: 3,
        }
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      handleDelete(row) {
        console.log(row);
        this.$confirm(this.$t("common.delete") + " " + row.branchName + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), { type: "warning" }).then(() => {
          this.$http.delete("branchAgency", row.id).then(() => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
    },
  };
</script>

<style scoped></style>
