<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.select') + $l('maintWorkOrder.maintItem','保养项目')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1200px"
    top="5vh"
    append-to-body
    @close="handleClear">
    <vm-table
      ref="selectTable"
      v-loading="itemSelectLoading"
      :filter.sync="search"
      multi-selection
      url="maintItem"
      :reserve-selection="true"
      @row-click="handleRowClick"
      @select="handleSelectionChange"
      @select-all="handleSelectAll"
      @get-table-data="setDefaultSelection">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.itemName','保养项名称')">
          <el-input v-model.trim="search.itemName" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('maintWorkOrder.productTypeName','保养区域')">
          <el-select
            v-model="search.maintArea"
            :placeholder="$t('common.pleaseSelect')"
            clearable>
            <el-option
              v-for="item in maintAreaOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-button slot="toolbar" @click="handleMultiSelect">{{$l("maintWorkOrder.confirmChoice", "确定选择")}}</el-button>
      <el-button slot="toolbar" @click="handleClear">{{$l("common.clear", "清空选择")}}</el-button>
      <el-table-column prop="itemName" :label="$l('maintWorkOrder.itemName','保养项名称')"></el-table-column>
      <el-table-column prop="maintContent" :label="$l('maintWorkOrder.maintContent','保养内容')"></el-table-column>
      <el-table-column prop="maintRequire" :label="$l('maintWorkOrder.maintRequire','保养要求')"></el-table-column>
      <el-table-column prop="maintArea" :label="$l('maintWorkOrder.maintArea','保养区域')">
        <template v-slot="scope">
          <span v-if="scope.row.maintArea === '机房'">
            {{$l("maintWorkOrder.maintenanceArea.machineRoom","机房")}}
          </span>
          <span v-else-if="scope.row.maintArea === '轿厢'">
            {{$l("maintWorkOrder.maintenanceArea.bridge","轿厢")}}
          </span>
          <span v-else-if="scope.row.maintArea === '层站'">
            {{$l("maintWorkOrder.maintenanceArea.layer","层站")}}
          </span>
          <span v-else-if="scope.row.maintArea === '井道及底坑'">
            {{$l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit","井道及底坑")}}
          </span>
          <span v-else-if="scope.row.maintArea === '扶梯'">
            {{$l("maintWorkOrder.maintenanceArea.escalator","扶梯")}}
          </span>
          <span v-else-if="scope.row.maintArea === '其他'">
            {{$l("maintWorkOrder.maintenanceArea.other","其他")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="$l('maintWorkOrder.remark','备注')"></el-table-column>
      <el-table-column prop="isDefault" :label="$l('maintWorkOrder.isDefault','默认')" align="center" width="100px">
        <template v-slot="scope">
          <el-tag v-if="scope.row.isDefault === 1" type="success">
            {{$l("common.yes","是")}}
          </el-tag>
          <el-tag v-else-if="scope.row.isDefault === 0" type="info">
            {{$l("common.no","否")}}
          </el-tag>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        itemSelectLoading: true,
        dialogVisible: false,
        multipleSelection: [],
        itemIdList: [],
        maintAreaOption: [
          {value: "机房", label: this.$l("maintWorkOrder.maintenanceArea.machineRoom","机房")},
          {value: "轿厢", label: this.$l("maintWorkOrder.maintenanceArea.bridge","轿厢")},
          {value: "层站", label: this.$l("maintWorkOrder.maintenanceArea.layer","层站")},
          {value: "井道及底坑", label: this.$l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit","井道及底坑")},
          {value: "扶梯", label: this.$l("maintWorkOrder.maintenanceArea.escalator","扶梯")},
          {value: "其他", label: this.$l("maintWorkOrder.maintenanceArea.other","其他")},
        ],
        search: {
          itemName: "",
          maintArea: "",
        },
      };
    },
    methods: {
      open(itemList) {
        this.multipleSelection = JSON.parse(JSON.stringify(itemList));
        this.itemIdList = itemList.map(item => item.id);
        this.dialogVisible = true;
        this.getList(1);
      },
      getList(pageNum) {
        this.$nextTick(() => {
          this.$refs.selectTable.getList(pageNum);
        });
        this.itemSelectLoading = false;
      },
      setDefaultSelection() {
        this.$nextTick(() => {
          this.$refs.selectTable.tableData.forEach(item => {
            if (this.itemIdList.includes(item.id)) {
              this.$refs.selectTable.$refs.elTable.toggleRowSelection(item, true);
            }
          });
        });
      },
      handleSelectionChange(selection, row) {
        if (this.itemIdList.includes(row.id)) {
          this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
          this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
        } else {
          this.multipleSelection.push(row);
          this.itemIdList.push(row.id);
        }
      },
      handleRowClick(row) {
        this.$refs.selectTable.$refs.elTable.toggleRowSelection(row);
        if (this.itemIdList.includes(row.id)) {
          this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
          this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
        } else {
          this.multipleSelection.push(row);
          this.itemIdList.push(row.id);
        }
      },
      handleMultiSelect() {
        this.$emit("multi-select", this.multipleSelection);
        this.dialogVisible = false;
      },
      handleClear() {
        this.$refs.selectTable.$refs.elTable.clearSelection();
        this.multipleSelection = [];
        this.itemIdList = [];
      },
      handleSelectAll(selection) {
        if (selection.length > 0) {
          let selectionList = selection.map(item => item.id);
          this.$refs.selectTable.tableData.forEach(row => {
            if (!selectionList.includes(row.id) && this.itemIdList.includes(row.id)) {
              this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
              this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
            } else {
              if (!this.itemIdList.includes(row.id)) {
                this.multipleSelection.push(row);
                this.itemIdList.push(row.id);
              }
            }
          });
        } else {
          this.$refs.selectTable.tableData.forEach(row => {
            if (this.itemIdList.includes(row.id)) {
              this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
              this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
            }
          });
        }
      },
    },
  };
</script>

<style scoped>

</style>