<template>
  <div>
    <el-tabs @tab-click="handleTabClick">
      <el-tab-pane v-if="typeAuth" :label="$l('maintWorkOrder.maintType', '保养类型')">
        <vm-table ref="vmTable" v-loading="loading" :filter.sync="typeSearch" url="maintType">
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="typeSearch.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.productTypeName', '梯种')">
              <el-select v-model="typeSearch.elevatorProductType" :placeholder="$t('common.pleaseSelect')" clearable>
                <el-option
                  v-for="item in productTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
          </template>
          <template slot="toolbar">
            <el-button class="addBtn" icon="el-icon-plus" @click="handleAddType">
              {{ $t("common.add") }}
            </el-button>
          </template>
          <el-table-column
            prop="maintTypeName"
            :label="$l('maintWorkOrder.maintTypeName', '名称')"
            width="100"
            align="center"></el-table-column>
          <el-table-column prop="elevatorProductType" :label="$l('maintWorkOrder.productTypeName', '梯种')">
            <template v-if="!$i18n.isCn" v-slot="scope">
              <span v-if="scope.row.elevatorProductType === '曳引驱动乘客电梯'">
                {{ "Traction Drive Passenger Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '曳引驱动载货电梯'">
                {{ "Traction Drive Freight Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '强制驱动载货电梯'">
                {{ "Forced Drive Freight Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '液压乘客电梯'">
                {{ "Hydraulic Passenger Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '液压载货电梯'">
                {{ "Hydraulic Freight Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '自动扶梯'">
                {{ "Escalator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '自动人行道'">
                {{ "Moving Walk" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '防爆电梯'">
                {{ "Explosion-proof Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '消防员电梯'">
                {{ "Firefighter Elevator" }}
              </span>
              <span v-if="scope.row.elevatorProductType === '杂物电梯'">
                {{ "Debris Elevator" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="maintTypeClassify"
            :label="$l('maintWorkOrder.maintTypeClassify', '分类')"
            width="100"
            align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintTypeClassify === '半月保'">
                {{ $l("maintWorkOrder.type.halfMonth", "半月保") }}
              </span>
              <span v-else-if="scope.row.maintTypeClassify === '季保'">
                {{ $l("maintWorkOrder.type.month", "季保") }}
              </span>
              <span v-else-if="scope.row.maintTypeClassify === '半年保'">
                {{ $l("maintWorkOrder.type.halfYear", "半年保") }}
              </span>
              <span v-else-if="scope.row.maintTypeClassify === '年保'">
                {{ $l("maintWorkOrder.type.year", "年保") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$l('maintWorkOrder.remark', '备注')" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="isDefault" :label="$l('maintWorkOrder.isDefault', '默认')" align="center" width="80">
            <template v-slot="scope">
              <el-tag v-if="scope.row.isDefault === 1" type="success">
                {{ $l("common.yes", "是") }}
              </el-tag>
              <el-tag v-else-if="scope.row.isDefault === 0" type="info">
                {{ $l("common.no", "否") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" align="center" width="100">
            <template v-slot="scope">
              <el-button
                type="text"
                :disabled="scope.row.isDefault === 1 && currentUserType !== 0"
                @click="handleEditType(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button
                type="text"
                class="operateDelBtn"
                :disabled="scope.row.isDefault === 1 && currentUserType !== 0"
                @click="handleDeleteType(scope.row)">
                {{ $t("common.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane v-if="itemAuth" :label="$l('maintWorkOrder.maintItem', '保养项目')">
        <vm-table ref="itemTable" v-loading="itemLoading" :filter.sync="itemSearch" url="maintItem">
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.itemName', '保养项名称')">
              <el-input v-model.trim="itemSearch.itemName" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.maintArea', '保养区域')">
              <el-select v-model="itemSearch.maintArea" :placeholder="$t('common.pleaseSelect')" clearable>
                <el-option
                  v-for="item in maintAreaOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
          </template>
          <template slot="toolbar">
            <el-button class="addBtn" icon="el-icon-plus" @click="handleAddItem">
              {{ $t("common.add") }}
            </el-button>
          </template>
          <el-table-column
            prop="itemName"
            show-overflow-tooltip
            :label="$l('maintWorkOrder.itemName', '保养项名称')"></el-table-column>
          <el-table-column
            prop="maintContent"
            show-overflow-tooltip
            :label="$l('maintWorkOrder.maintContent', '保养内容')"></el-table-column>
          <el-table-column
            prop="maintRequire"
            show-overflow-tooltip
            :label="$l('maintWorkOrder.maintRequire', '保养要求')"></el-table-column>
          <el-table-column prop="maintArea" :label="$l('maintWorkOrder.maintArea', '保养区域')" width="100" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintArea === '机房'">
                {{ $l("maintWorkOrder.maintenanceArea.machineRoom", "机房") }}
              </span>
              <span v-else-if="scope.row.maintArea === '轿厢'">
                {{ $l("maintWorkOrder.maintenanceArea.bridge", "轿厢") }}
              </span>
              <span v-else-if="scope.row.maintArea === '层站'">
                {{ $l("maintWorkOrder.maintenanceArea.layer", "层站") }}
              </span>
              <span v-else-if="scope.row.maintArea === '井道及底坑'">
                {{ $l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit", "井道及底坑") }}
              </span>
              <span v-else-if="scope.row.maintArea === '扶梯'">
                {{ $l("maintWorkOrder.maintenanceArea.escalator", "扶梯") }}
              </span>
              <span v-else-if="scope.row.maintArea === '其他'">
                {{ $l("maintWorkOrder.maintenanceArea.other", "其他") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$l('maintWorkOrder.remark', '备注')" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="isDefault" :label="$l('maintWorkOrder.isDefault', '默认')" align="center" width="80">
            <template v-slot="scope">
              <el-tag v-if="scope.row.isDefault === 1" type="success">
                {{ $l("common.yes", "是") }}
              </el-tag>
              <el-tag v-else-if="scope.row.isDefault === 0" type="info">
                {{ $l("common.no", "否") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" align="center" width="100">
            <template v-slot="scope">
              <el-button
                type="text"
                :disabled="scope.row.isDefault === 1 && currentUserType !== 0"
                @click="handleEditItem(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button
                type="text"
                class="operateDelBtn"
                :disabled="scope.row.isDefault === 1 && currentUserType !== 0"
                @click="handleDeleteItem(scope.row)">
                {{ $t("common.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane v-if="settingAuth" :label="$l('maintWorkOrder.maintSetting', '保养设置')">
        <maint-setting></maint-setting>
      </el-tab-pane>
      <el-tab-pane v-if="$i18n.isCn && showOrgId" :label="$l('contract.branchAgency', '安全抽查')">
        <safe-check-setting-list></safe-check-setting-list>
      </el-tab-pane>
      <el-tab-pane :label="$l('contract.branchAgency', '区域')">
        <area-list></area-list>
      </el-tab-pane>
      <el-tab-pane :label="$l('contract.branchAgency', '分公司')">
        <branch-company-list></branch-company-list>
      </el-tab-pane>
      <el-tab-pane :label="$l('contract.branchAgency', '服务站')">
        <branch-station-list></branch-station-list>
      </el-tab-pane>
    </el-tabs>
    <type-edit-dialog ref="typeEditPage" @save-success="getList(-1)"></type-edit-dialog>
    <item-edit-dialog ref="itemEditPage" @save-success="getItemList(-1)"></item-edit-dialog>
  </div>
</template>
<script>
  import TypeEditDialog from "./maintTypeEdit.vue";
  import ItemEditDialog from "./maintItemEdit.vue";
  import auth from "@/util/auth";
  import MaintSetting from "@/views/newMaintWorkOrder/maintSetting/maintSettingList";
  import BranchAgencyList from "@/views/newMaintWorkOrder/maintSetting/areaList";
  import SafeCheckSettingList from "@/views/newMaintWorkOrder/maintSetting/SafeCheckSettingList";
  import AreaList from "@/views/newMaintWorkOrder/maintSetting/areaList";
  import BranchCompanyList from "@/views/newMaintWorkOrder/maintSetting/branchCompanyList";
  import BranchStationList from "@/views/newMaintWorkOrder/maintSetting/branchStationList";

  export default {
    components: { TypeEditDialog, ItemEditDialog, MaintSetting,AreaList,BranchCompanyList,BranchStationList, SafeCheckSettingList },
    data() {
      return {
        settingAuth: this.$auth(326),
        typeAuth: this.$auth(327),
        itemAuth: this.$auth(328),
        showOrgId: auth.getUsername() === "superAdmin" || auth.getUsername() === "admin",
        loading: true,
        itemLoading: true,
        currentUserType: auth.getUserType(),
        productTypeList: [],
        maintAreaOption: [
          { value: "机房", label: this.$l("maintWorkOrder.maintenanceArea.machineRoom", "机房") },
          { value: "轿厢", label: this.$l("maintWorkOrder.maintenanceArea.bridge", "轿厢") },
          { value: "层站", label: this.$l("maintWorkOrder.maintenanceArea.layer", "层站") },
          { value: "井道及底坑", label: this.$l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit", "井道及底坑") },
          { value: "扶梯", label: this.$l("maintWorkOrder.maintenanceArea.escalator", "扶梯") },
          { value: "其他", label: this.$l("maintWorkOrder.maintenanceArea.other", "其他") },
        ],
        typeSearch: {
          filter: "",
          elevatorProductType: "",
        },
        itemSearch: {
          itemName: "",
          maintArea: "",
        },
      };
    },
    mounted() {
      this.getList(1);
      this.getElevatorProductTypeList();
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      },
      getItemList(pageNum) {
        this.$refs.itemTable.getList(pageNum);
        this.itemLoading = false;
      },
      getElevatorProductTypeList() {
        this.$api.getList("elevatorProductTypes").then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = { value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeName };
            } else {
              item = { value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeNameEn };
            }
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$l("maintWorkOrder.tip.getElevatorProductTypeError", "获取电梯梯种失败") + "," + error.response.data.message);
          }
        });
      },
      handleTabClick(tab) {
        if (tab.index === "1") {
          this.getItemList(1);
        }
      },
      handleAddType() {
        this.$refs.typeEditPage.open(0);
      },
      handleEditType(row) {
        this.$refs.typeEditPage.open(row.id);
      },
      handleDeleteType(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$l("maintWorkOrder.maintType", "保养类型") + " " + row.maintTypeName + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("maintType", row.id).then(() => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
      handleAddItem() {
        this.$refs.itemEditPage.open(0);
      },
      handleEditItem(row) {
        this.$refs.itemEditPage.open(row.id);
      },
      handleDeleteItem(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$l("maintWorkOrder.maintItem", "保养项目") + " " + row.itemName + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("maintItem", row.id).then(() => {
            this.getItemList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
