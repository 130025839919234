<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="model.id?$l('common.edit','编辑'):$l('common.add','新增')"
    width="500px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      :label-width="$l('contract.labelWidth','80px')"
      :model="model">
      <div>
        <el-form-item :label="$l('contract.branchName','名称')" prop="branchName" :rules="$rule.notNull">
          <el-input v-model.trim="model.branchName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          id: 0,
          branchName: "",
          type: "",
        },
      };
    },
    methods: {
      open(id,type) {
        this.model.id = id;
        this.model.type = type;
        this.dialogVisible = true;
        if (this.model.id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("branchAgency/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("branchAgency", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style scoped>

</style>
