import { render, staticRenderFns } from "./branchAgencyEdit.vue?vue&type=template&id=2a8a628c&scoped=true"
import script from "./branchAgencyEdit.vue?vue&type=script&lang=js"
export * from "./branchAgencyEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8a628c",
  null
  
)

export default component.exports