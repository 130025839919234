<template>
  <div v-loading="loading">
    <el-form ref="formValidate" :model="maintSetting" :rules="ruleValidate">
      <el-form-item prop="autoSendDay" :label="$l('maintWorkOrder.setting.autoSendDay', '保养工单自动发送时间：提前')">
        <el-input v-model.number="maintSetting.autoSendDay" :placeholder="'0-7'" style="width: 67px">
          <template slot="suffix">{{ $l("maintWorkOrder.setting.day", "天") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="maintDistance" :label="$l('maintWorkOrder.setting.maintDistance', '维保作业距离：')">
        <el-input v-model.number="maintSetting.maintDistance" :placeholder="'> 0'" :style="{ width: $l('100px', '80px') }">
          <template slot="suffix">{{ $l("maintWorkOrder.setting.meter", "米") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.isAutoGenerate', '是否自动生成保养工单：')">
        <el-switch v-model="maintSetting.isAutoGenerate" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.generateInitialTime', '自动生成工单使用的初始时间：')">
        <el-radio-group v-model="maintSetting.generateInitialTime">
          <el-radio :label="1"> {{ $l('maintWorkOrder.setting.completeDate', '完成时间') }}</el-radio>
          <el-radio :label="2"> {{ $l('maintWorkOrder.setting.planDate', '计划时间') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="maintCycleDay" :label="$l('maintWorkOrder.setting.maintCycleDay', '保养周期天数：')">
        <el-input v-model.number="maintSetting.maintCycleDay" :placeholder="'1-90'" style="width: 75px">
          <template slot="suffix">{{ $l("maintWorkOrder.setting.day", "天") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.signInMethod', '作业签到方式：')">
        <el-radio-group v-model="maintSetting.signInMethod">
          <el-radio :label="1"> {{ $l('maintWorkOrder.setting.photo', '拍照') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.isSignInApp', '是否允许客户在维保人员的APP端进行签字：')">
        <el-switch v-model="maintSetting.isSignInApp" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.isOperationOverdue', '保养工单超期后，是否可继续操作：')">
        <el-switch v-model="maintSetting.isOperationOverdue" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.isTwoMaintainerCheckIn', '是否允许两个维保人员均可进行工单签到：')">
        <el-switch v-model="maintSetting.isTwoMaintainerCheckIn" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.isPaperMaintPhotoRequired', '是否要求必须上传纸质保养单照片：')">
        <el-switch v-model="maintSetting.isPaperMaintPhotoRequired" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$l('maintWorkOrder.setting.fieldWorkMethods', '现场作业方式：')"></el-form-item>
      <el-form-item>
        <el-radio-group v-model="customDisable" @change="handleCustom">
          <div>
            <el-radio :label="true">
              {{ $l('maintWorkOrder.setting.default', '默认：现场作业除"签到"和"完成"操作外，作业中不限制其他操作必须完成') }}</el-radio>
          </div>
          <div style="margin-top: 10px">
            <el-radio :label="false"> {{ $l('maintWorkOrder.setting.custom', '自定义') }}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isPhotoCheckIn" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isPhotoCheckIn', '必须拍照签到')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isMaintainerMustSignInApp" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isMaintainerMustSignInApp', '维保人员完成工单前必须在App上进行签字')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item prop="isPhotoAbnormal" style="margin-left: 40px">
        <el-switch v-model="maintSetting.isPhotoAbnormal" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isPhotoAbnormal', '对异常的保养项目，必须拍照')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isSoundRecordAbnormal" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isSoundRecordAbnormal', '对异常的保养项目，必须录音')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isContextAbnormal" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isContextAbnormal', '对异常的保养项目，必须填写文字描述')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item prop="isPhotoNormal" style="margin-left: 40px">
        <el-switch v-model="maintSetting.isPhotoNormal" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isPhotoNormal', '对正常的保养项目，必须拍照')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isSoundRecordNormal" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isSoundRecordNormal', '对正常的保养项目，必须录音')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isContextNormal" :disabled="customDisable"
          :active-text="$l('maintWorkOrder.setting.isContextNormal', '对正常的保养项目，必须填写文字描述')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
    </el-form>
    <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
      {{ $l("common.save", "保存设置") }}
    </el-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    systemCode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      customDisable: true,
      moduleName: "maintSetting",
      maintSetting: {
        id: null,
        autoSendDay: 3,
        maintDistance: 1000,
        isAutoGenerate: 0,
        generateInitialTime: 2,
        maintCycleDay: 15,
        signInMethod: 1,
        isSignInApp: 1,
        isOperationOverdue: 1,
        isTwoMaintainerCheckIn: 0,
        isPaperMaintPhotoRequired: 0,
        isPhotoCheckIn: 0,
        isMaintainerMustSignInApp: 0,
        isPhotoAbnormal: 0,
        isSoundRecordAbnormal: 0,
        isContextAbnormal: 0,
        isPhotoNormal: 0,
        isSoundRecordNormal: 0,
        isContextNormal: 0,
        orgId: "",
        creatorId: "",
      },
      ruleValidate: {
        autoSendDay: [
          {
            type: "number",
            min: 0,
            max: 7,
            message: this.$l("maintWorkOrder.setting.sendTip", "请输入一个介于0和7之间的值，0值代表不自动发送"),
            trigger: "blur",
          },
        ],
        maintDistance: [
          {
            type: "number",
            min: 0,
            message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
            trigger: "blur",
          },
        ],
        maintCycleDay: [
          {
            type: "number",
            min: 1,
            max: 90,
            message: this.$l("maintWorkOrder.setting.cycleTip", "请输入一个介于1和90之间的值"),
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.systemCode === "admin") {
        this.moduleName = "maintSetting/global";
      }
      this.$api.getList(this.moduleName).then(res => {
        if (res.data) {
          this.maintSetting = Object.assign(this.maintSetting, res.data);
        }
        this.loading = false;
        if (this.maintSetting.isPhotoCheckIn === 1 ||
          this.maintSetting.isMaintainerMustSignInApp === 1 ||
          this.maintSetting.isPhotoAbnormal === 1 ||
          this.maintSetting.isSoundRecordAbnormal === 1 ||
          this.maintSetting.isContextAbnormal === 1 ||
          this.maintSetting.isPhotoNormal === 1 ||
          this.maintSetting.isSoundRecordNormal === 1 ||
          this.maintSetting.isContextNormal === 1) {
          this.customDisable = false;
        }
      }).catch((error) => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    handleCustom() {
      if (this.customDisable) {
        this.maintSetting.isPhotoCheckIn = 0;
        this.maintSetting.isMaintainerMustSignInApp = 0;
        this.maintSetting.isPhotoAbnormal = 0;
        this.maintSetting.isSoundRecordAbnormal = 0;
        this.maintSetting.isContextAbnormal = 0;
        this.maintSetting.isPhotoNormal = 0;
        this.maintSetting.isSoundRecordNormal = 0;
        this.maintSetting.isContextNormal = 0;
      }
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$http.post(this.moduleName, this.maintSetting).then(() => {
            this.submitLoading = false;
            this.$message.success(this.$t("common.tip.saveSuccess"));
            this.getData();
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>